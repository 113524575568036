<template>
    <!-- Withdraw Action Sheet -->
    <div class="modal fade action-sheet" id="withdrawTradeInterest" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Withdraw Interest</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <form @submit.prevent="withdraw">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="account2d">From</label>
                                    <select class="form-control custom-select" id="account2d">
                                        <option :value="cryptoID">{{cryptoID}} Trade Interest</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group basic">
                                <label class="label">Amount</label>
                                <div class="input-group mb-2">
                                    <span class="input-group-text" id="basic-addonb1">$</span>
                                    <input type="number" class="form-control" placeholder="amount" :value="amount.toFixed(4)" readonly>
                                </div>
                            </div>

                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="text11d">To</label>
                                    <input type="text" class="form-control" id="text11d" :placeholder="`Enter a valid ${cryptoID} address`" v-model="address">
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                            </div>

                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="address.length <= 25">
                                    Withdraw</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- * Withdraw Action Sheet -->
</template>

<script>
export default {
    name: "withdrawTradeInterest",
    data(){
        return {
            address: ""
        }
    },
    props: ['cryptoID', 'amount'],
    methods: {
        async withdraw(){
            if(this.address.length > 25){
                this.$loader.show();
                const response = await this.$store.dispatch('wallet/transferTradeInterestToExternalWaller', {
                    cryptoId: this.cryptoID,
                    amount: this.amount,
                    address: this.address
                });
                this.$loader.hide();
                if(response.status){
                    await this.$store.dispatch('notification/display_noti', {
                        message: `Operation successful`,
                        timeout: 1500
                    });
                    $('#withdrawTradeInterest').modal('hide');
                    return this.$router.push({name: 'MetricsWallet'})
                }else{
                    return this.$store.dispatch('notification/display_noti', {
                        message: response.message,
                        timeout: 1500
                    });
                }
            }

        }
    },
    mounted() {
        const withdrawModal = $('#withdrawTradeInterest');
        // withdrawModal.modal({
        //     backdrop: 'static'
        // });
        withdrawModal.on('hidden.bs.modal', ()=>{
            this.address = "";
        });
    }
}
</script>

<style scoped>

</style>