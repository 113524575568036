import { render, staticRenderFns } from "./withdrawTradeInterest.vue?vue&type=template&id=fabc07ea&scoped=true&"
import script from "./withdrawTradeInterest.vue?vue&type=script&lang=js&"
export * from "./withdrawTradeInterest.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fabc07ea",
  null
  
)

export default component.exports