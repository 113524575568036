<template>
    <div id="appCapsule">
       <div class="section">
           <div class="d-flex justify-content-start trade-head-txt pt-3 ps-1">
               <img class="crypto-img i-1x" v-if="$route.params.cryptoId==='DOT'" src="../assets/img/icon/Polkadot_symbol_color.svg">
               <img class="crypto-img i-1x" v-if="$route.params.cryptoId==='SAFEMOON'" src="../assets/img/icon/safemoon_normal_dark.svg">
               <img class="crypto-img i-1x" v-if="$route.params.cryptoId==='KLV'" src="../assets/img/icon/klv.png">
               <img class="crypto-img i-1x" v-if="$route.params.cryptoId==='SHIB'" src="../assets/img/icon/shib-logo.svg">
               <img class="crypto-img i-1x" v-if="$route.params.cryptoId==='DAI'" src="../assets/img/icon/dai.png">
               <img class="crypto-img i-1x" v-if="$route.params.cryptoId==='USDC'" src="../assets/img/icon/usdc.png">
               <img class="crypto-img i-1x" v-if="$route.params.cryptoId==='CHZ'" src="../assets/img/icon/chz.png">
               <img class="crypto-img i-1x" v-if="$route.params.cryptoId==='MATIC'" src="../assets/img/icon/matic.png">
               <img class="crypto-img i-1x" v-if="$route.params.cryptoId==='UTK'" src="../assets/img/icon/utk.png">
               <img class="crypto-img i-1x me-1" v-if="$route.params.cryptoId==='AXS'" src="../assets/img/icon/axs.png">
               <i v-else :class="`cc ${$route.params.cryptoId} fa-3x`"></i>
               <h2 class="txt-info">{{$route.params.cryptoId}}</h2>
           </div>
           <div v-if="loading" class="text-center mt-5">
               <h3>Loading...</h3>
               <p>Please wait</p>
           </div>
           <div class="mt-5" v-else-if="error">
               <div class="alert alert-outline-danger text-center">{{message}}</div>
           </div>
           <div class="container-fluid tradeinfo" v-else>
               <div class="row">
                   <div class="col-7">Total amount</div>
                   <div class="col-5">{{assetTrade.subscription.amount}}</div>
               </div>
               <div class="row">
                   <div class="col-7">Estimated Yield</div>
                   <div class="col-5">{{assetTrade.subscription.plan_percentage*100}}% + {{assetTrade.elapse_percentage*100}}%</div>
               </div>
               <div class="row">
                   <div class="col-7">Stake Date</div>
                   <div class="col-5">{{getReadableDatetime(assetTrade.created_at)}}</div>
               </div>
               <div class="row">
                   <div class="col-7">Locked Period</div>
                   <div class="col-5">{{assetTrade.duration+assetTrade.elapse_duration}} days</div>
               </div>
               <div class="row">
                   <div class="col-7">Accure Days</div>
                   <div class="col-5">{{getDayDiff(assetTrade.created_at)}} days</div>
               </div>
               <div class="row">
                   <div class="col-7">Cumulative Interest</div>
                   <div class="col-5">{{assetTrade.earned_amount.toFixed(4)}}</div>
               </div>
               <div class="row justify-content-center mt-5">
                   <template v-if="assetTrade.elapse_active && !assetTrade.withdrawn">
                       <div class="col-12 text-center">
                           <h5>
                               Your {{assetTrade.subscription.plan_percentage*100}}% earn interest is now available for withdrawal
                           </h5>
                       </div>
                       <button class="btn btn-primary mt-3" @click.prevent="withdraw"> Withdraw to your {{$route.params.cryptoId}} Wallet</button>
                       <button class="btn btn-outline-primary mt-3" @click.prevent="withdrawToExternal"> Withdraw to external wallet </button>
                   </template>
                   <template v-else-if="assetTrade.withdrawn">
                       <div class="col-12 text-center">
                           <h5>Your {{assetTrade.subscription.plan_percentage*100}}% earn interest have been withdrawn </h5>
                       </div>
                   </template>
                   <template v-else>
                       <div class="col-12 text-center">
                           <h5> Your {{assetTrade.subscription.plan_percentage*100}}% earn interest will be available for withdrawal in {{Math.max(0, assetTrade.duration-getDayDiff(assetTrade.duration))}} days</h5>
                       </div>
                   </template>

               </div>
           </div>
       </div>
        <withdraw-trade-interest v-bind="payload" />
    </div>
</template>

<script>

import {AutoTradeModel} from "../models/payments/payment";
import methodsMixin from "../utils/methodsMixin";
import WithdrawTradeInterest from "../components/modals/assets/withdrawTradeInterest";


export default {
    name: "autoTrade",
    components: {WithdrawTradeInterest},
    data(){
        return {
            loading: false,
            error: false,
            message: '',
            assetTrade: new AutoTradeModel(),

            payload: {
                amount: 0,
                cryptoID: ''
            }
        }
    },
    mixins: [methodsMixin],
    methods: {
        async fetchTrades(){
            this.loading = true;
            this.error = false;
            this.message = '';
            const {cryptoId} = this.$route.params;
            let response = await this.$store.dispatch('user/getUser', this.$store.getters['user/getUser'].id);
            if(response.status){
                const user_trade_asset = response.data.data.auto_trade_assets[cryptoId];
                if(user_trade_asset){
                    if(Object.entries(user_trade_asset).length > 0){
                        if(user_trade_asset.enabled){
                            this.assetTrade = user_trade_asset
                        }else{
                            this.error = true;
                            this.message = "No running trades"
                        }
                    }else{
                        this.error = true;
                        this.message = "No running trades"
                    }
                }else{
                    this.error = true;
                    this.message = "No running trades"
                }

            }else{
                this.error = true;
                this.message = "An unknown error occurred"
            }
            this.loading = false
        },
        async withdraw(){
            this.$loader.show();
            const response = await this.$store.dispatch('wallet/transferTradeInterest', {
                cryptoId: this.$route.params.cryptoId,
                amount: this.assetTrade.subscription.earned_amount
            });
            this.$loader.hide();
            if(response.status){
                await this.$store.dispatch('notification/display_noti', {
                    message: `Operation successful`,
                    timeout: 1500
                });
                return this.$router.push({name: 'MetricsWallet'})
            }else{
                return this.$store.dispatch('notification/display_noti', {
                    message: response.message,
                    timeout: 1500
                });
            }
        },
        withdrawToExternal(){
            this.payload.amount = this.assetTrade.subscription.earned_amount;
            this.payload.cryptoID = this.$route.params.cryptoId;
            $('#withdrawTradeInterest').modal('show');
        }
    },
    mounted() {
        this.fetchTrades();
    }
}
</script>

<style scoped>
.txt-info{
    font-size: 1.5em;
}
.tradeinfo .row{
    margin-top: 15px;
}
.tradeinfo .row .col-5{
    text-align: right;
    color: #27173E;
}
</style>